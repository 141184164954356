import { BaseHttpRepository } from 'infraestructure/repositories';
import { CompanyResponse } from 'domain/PayloadResponse/Company';
import { SellingPointResponse } from 'domain/PayloadResponse/SellingPoint';
import { comerciosLocales } from './comerciosLocales';

// IDs de sucursales a ocultar
const SUCURSALES_OCULTAS = [
  { companyId: 1, sellingPointId: 10179 }, // SINSA - Sucursal 10179
  { companyId: 1, sellingPointId: 10180 }, // SINSA - Sucursal 10180
  { companyId: 1, sellingPointId: 10343 }, // SINSA - Sucursal 10343
  { companyId: 1, sellingPointId: 10178 }, // SINSA - Sucursal 10178
  { companyId: 1, sellingPointId: 10178 }, // SINSA - Sucursal 10178
  { companyId: 1, sellingPointId: 10184 }, // SINSA - Sucursal 10184
];

// IDs de compañías a ocultar completamente
const COMPANIAS_OCULTAS: number[] = [];

export class CompanyRepository extends BaseHttpRepository {
  endpoint = 'admin';

  async getCompanies(
    page: number,
    searchCompany: string,
    country: string | null
  ): Promise<CompanyResponse> {
    // Datos desde la API
    const response = await this.client.get(this.buildUrl('company/search'), {
      query: searchCompany,
      country: country,
      page: page,
    });

    const apiCompanies = (response.data as CompanyResponse).results || [];

    // Filtrar comercios locales por nombre o categoría
    const filteredLocalCompanies = comerciosLocales.filter((localCompany) =>
      searchCompany
        ? localCompany.companyName
            .toLowerCase()
            .includes(searchCompany.toLowerCase()) ||
          localCompany.category
            .toLowerCase()
            .includes(searchCompany.toLowerCase())
        : true
    );

    // Combinar los resultados de la API y los comercios locales filtrados
    let combinedCompanies = apiCompanies;
    if (page === 1) {
      combinedCompanies = [...apiCompanies, ...filteredLocalCompanies];
    }

    // Filtrar compañías ocultas (si hay alguna)
    const visibleCompanies =
      COMPANIAS_OCULTAS.length > 0
        ? combinedCompanies.filter(
            (company) => !COMPANIAS_OCULTAS.includes(company.companyId)
          )
        : combinedCompanies;

    return {
      ...(response.data as CompanyResponse),
      results: visibleCompanies,
    };
  }

  async getSellingPoints(
    companyId: number,
    country: string | null
  ): Promise<SellingPointResponse> {
    const response = await this.client.get(
      this.buildUrl('sellingpoint/withtime'),
      {
        companyId: companyId,
        country: country,
      }
    );

    const apiSellingPoints =
      (response.data as SellingPointResponse).results || [];

    const localCompany = comerciosLocales.find(
      (company) => company.companyId === companyId
    );

    const localSellingPoints = localCompany?.sellingPoints || [];
    const allSellingPoints = [...apiSellingPoints, ...localSellingPoints];

    // Filtrar sucursales ocultas
    const filteredSellingPoints = allSellingPoints.filter(
      (sp) =>
        !SUCURSALES_OCULTAS.some(
          (hidden) =>
            hidden.companyId === sp.companyId &&
            (!hidden.sellingPointId || hidden.sellingPointId === sp.sellingPointId)
        )
    );

    return {
      ...(response.data as SellingPointResponse),
      results: filteredSellingPoints,
    };
  }

  // Obtener una compañía específica, considerando los comercios locales
  async getSpecificCompany(companyId: number): Promise<CompanyResponse> {
    // Verificar si la compañía está oculta
    if (COMPANIAS_OCULTAS.includes(companyId)) {
      return {
        count: 0,
        results: [],
      };
    }

    const response = await this.client.get(this.buildUrl('company'), {
      isCA: 1,
      companyId: companyId,
    });

    const apiCompany = (response.data as CompanyResponse).results[0];
    if (apiCompany) {
      return response.data as CompanyResponse;
    }

    // Buscar en comercios locales
    const localCompany = comerciosLocales.find(
      (company) => company.companyId === companyId
    );

    if (localCompany) {
      return {
        count: 1,
        results: [localCompany],
      };
    }

    return {
      count: 0,
      results: [],
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async affiliate(data: any): Promise<any> {
    return await this.client.post(this.buildUrl('company/affiliate'), data);
  }
}
