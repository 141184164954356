import ferrex from '../../../assets/images/logos/ferrex.jpg';
import galeron_ceramica from '../../../assets/images/logos/galeron_de_la_ceramica.jpg';

export const comerciosLocales = [
  {
    companyId: -1,
    companyName: 'Ferrex',
    code: 'CAL1',
    uId: 'uuid-1001',
    countryId: 1,
    opstatus: 1,
    isCa: 1,
    isCollector: true,
    logo: ferrex,
    category: 'Construcción',
    sellingPoints: [
      {
        companyId: -1,
        sellingPointId: 1,
        name: 'Ferrex Huembe',
        code: 'SF1',
        address: 'Semaforos del hospital del niño 50 metros al norte',
        telephone: '75504253',
        fax: '',
        email: 'milton.gamoneda@sinsa.com.ni',
        department: 'Managua',
        scheduleMonFri: "07:00 a.m. - 06:00 p.m.",
        scheduleSat: "08:00 a.m. - 12:00 p.m.",
        scheduleSun: 'Cerrado',
        isOpen: true,
      },
    ],
  },
  {
    companyId: -2,
    companyName: 'Galeron de la ceramica',
    code: 'CAL2',
    uId: 'uuid-1002',
    countryId: 1,
    opstatus: 1,
    isCa: 1,
    isCollector: true,
    logo: galeron_ceramica,
    category: 'Construcción',
    sellingPoints: [
      {
        companyId: -2, // Agregar companyId aquí
        sellingPointId: 1,
        name: 'Galerón Ciudad Sandino',
        code: 'SGC1',
        address:
          'Galerón Ciudad Sandino, De la estación de bomberos 50 metros al este',
        telephone: '22787777',
        fax: '',
        email: 'oscar.delgado@sinsa.com.ni',
        scheduleMonFri: "07:00 a.m. - 06:00 p.m.",
        scheduleSat: "08:00 a.m. - 12:00 p.m.",
        scheduleSun: 'Cerrado',
        isOpen: true,
        department: 'Managua',
      },
      {
        companyId: -2, // Agregar companyId aquí
        sellingPointId: 2,
        name: 'Galeron Sebaco',
        code: 'SGC2',
        address: 'Sebaco, de tipicos daysi, 1/2 cuadra al oeste',
        telephone: '77631554',
        fax: "",
        email: 'milton.hernandez@sinsa.com.ni',
        scheduleMonFri: "07:00 a.m. - 06:00 p.m.",
        scheduleSat: "08:00 a.m. - 12:00 p.m.",
        scheduleSun: 'Cerrado',
        isOpen: true,
        department: 'Matagalpa',
      },
      {
        companyId: -2, // Agregar companyId aquí
        sellingPointId: 3,
        name: "Galerón Boaco",
        code: "SGC3",
        address: "BARRIO SANTA ISABEL CONTIGUO AL SUPER EXPRESS",
        telephone: "82443113",
        fax: "",
        email: "bayardo.prado@sinsa.com.ni",
        scheduleMonFri: "07:00 a.m. - 06:00 p.m.",
        scheduleSat: "08:00 a.m. - 12:00 p.m.",
        scheduleSun: 'Cerrado',
        isOpen: true,
        department: 'Chontales',
      },
      {
        companyId: -2, 
        sellingPointId: 4,
        name: "Galeron Chichigalpa",
        code: "SGC4",
        address: "Chichigalpa, Frente al INSS",
        telephone: "78725598",
        fax: "",
        email: "eduardo.martinez@sinsa.com.ni",
        scheduleMonFri:  "07:00 a.m. - 06:00 p.m.",
        scheduleSat: "08:00 a.m. - 12:00 p.m.",
        scheduleSun: 'Cerrado',
        isOpen: true,
        department: 'Chinandega',
      },
    ],
  },
];
